var render = function () {
  var _vm$invoiceBasisTempl;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": _vm.modalData.modalHeadline,
      "modalType": _vm.modalData.modalType,
      "editable": ""
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      },
      "submit": _vm.onSubmit
    }
  }, [_vm.editPartInvoiceModalData.showModal ? _c('BaseModal', {
    on: {
      "close": function close($event) {
        _vm.editPartInvoiceModalData.showModal = false;
      }
    }
  }, [_c('PartInvoiceBasisForm', {
    attrs: {
      "modalData": _vm.editPartInvoiceModalData,
      "invoicePartBasis": _vm.editPartInvoiceModalData.existingItem,
      "invoiceBasisType": _vm.invoiceBasisType,
      "invoice-part-bases": ((_vm$invoiceBasisTempl = _vm.invoiceBasisTemplate) === null || _vm$invoiceBasisTempl === void 0 ? void 0 : _vm$invoiceBasisTempl.clientAccountInvoicePartBasis) || [],
      "isCreating": true,
      "templateQueries": _vm.templateQueries
    },
    on: {
      "close": function close($event) {
        _vm.editPartInvoiceModalData.showModal = false;
      },
      "update": _vm.updateInvoicePartBasis
    }
  })], 1) : _vm._e(), _vm.loadingTemplate ? _c('v-overlay', {
    attrs: {
      "absolute": "",
      "color": "white",
      "opacity": "0"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "size": 64,
      "indeterminate": "",
      "color": "primary"
    }
  })], 1) : [_vm._v(" Du oppretter nå Hovedgrunnlag for " + _vm._s(_vm.course.courseName) + " "), _c('InvoiceBasisDetails', {
    attrs: {
      "isTemplate": "",
      "invoiceBasisDetails": _vm.invoiceBasisTemplate,
      "invoiceBasisType": _vm.invoiceBasisType
    }
  }), _c('div', {
    staticClass: "mt-4"
  }, [_c('BaseTableFiltered', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.invoiceBasisTemplate ? _vm.invoiceBasisTemplate.clientAccountInvoicePartBasis : []
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title font-weight-light text-center",
          staticStyle: {
            "align-self": "center"
          }
        }, [_vm._v("Delgrunnlag")])];
      },
      proxy: true
    }, {
      key: "item.memberOrganizationId",
      fn: function fn(_ref) {
        var value = _ref.value;
        return [_vm._v(" " + _vm._s(_vm.getOrgName(value)) + " ")];
      }
    }, {
      key: "item.shareOfRegisteredCosts",
      fn: function fn(_ref2) {
        var value = _ref2.value;
        return [_vm._v(" " + _vm._s(_vm.formatCurrency(value)) + " ")];
      }
    }, {
      key: "item.change",
      fn: function fn(_ref3) {
        var value = _ref3.value;
        return [_vm._v(" " + _vm._s(_vm.formatCurrency(value)) + " ")];
      }
    }, {
      key: "item.shareOfCourseFee",
      fn: function fn(_ref4) {
        var value = _ref4.value;
        return [_vm._v(" " + _vm._s(_vm.formatCurrency(value)) + " ")];
      }
    }, {
      key: "item.shareOfTotalCosts",
      fn: function fn(_ref5) {
        var value = _ref5.value;
        return [_vm._v(" " + _vm._s(_vm.formatCurrency(value)) + " ")];
      }
    }, {
      key: "actions",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref7) {
              var on = _ref7.on,
                  attrs = _ref7.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                on: {
                  "click": function click($event) {
                    return _vm.displayPartInvoiceModal(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-pencil ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Rediger delgrunnlag")])])];
      }
    }], null, true)
  })], 1)]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }